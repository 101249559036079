import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQueryParams } from "../../hooks";
import RichText from "../Prismic/RichText";
import SectionHeading from "../SectionHeading";
import "./GladlyHelp.scss";

const GladlyHelp = (props) => {
  const {
    params: { message },
  } = useQueryParams();

  useEffect(() => {
    // Attach Gladly script to the DOM
    const gladlyScript = document.createElement("script");
    gladlyScript.src = "https://cdn.gladly.com/help-center/hcl.js";
    gladlyScript.async = true;
    document.body.appendChild(gladlyScript);

    // Configure Gladly script
    window.gladlyHCConfig = {
      api: "https://wonderbly.us-1.gladly.com",
      orgId: "W5Lha070SWCLB6uK4NR4BA",
      brandId: props.scriptId,
      cdn: "https://cdn.gladly.com",
      selector: "#gladly-help-center",
    };

    return () => {
      document.body.removeChild(gladlyScript);
    };
  }, []);

  return (
    <section className="GladlyHelp">
      <div className="container-guttered">
        {props.title && <SectionHeading tag="h3">{props.title}</SectionHeading>}
        {message === "success" && (
          <RichText
            className="GladlyHelp__success"
            fields={props.confirmationMessage}
          />
        )}
        <div id="gladly-help-center" />
      </div>
    </section>
  );
};

GladlyHelp.propTypes = {
  title: PropTypes.string,
  scriptId: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.array,
};

export default GladlyHelp;
